//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import OpenData from '@/views/fileStream/documentManage/weixin/openData'

import { STable } from '@/components'
import { Empty } from 'ant-design-vue'
import { sysRoleManageList, sysRoledelManage } from '@/api/modular/system/roleManage'
export default {
  components: {
    STable,
    OpenData,
  },
  data() {
    return {
      visible: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
      userList: [],
      checkeduserIdList: [],
      roleId: '',
      corpId: '',
    }
  },
  created() {},
  methods: {
    open({ id, corpId }) {
      console.log('id++++++++',id)
      if (id) {
        this.checkeduserIdList = []
        this.visible = true
        this.corpId = corpId
        this.roleId = id
        this.getuserManageList(id, corpId)
      }
    },
    // 獲取當前授權用戶列表
    getuserManageList(id, corpId) {
      id &&
        sysRoleManageList(id).then((res) => {
          const { data, success } = res
          if (success) {
            this.userList = data.map((item) => {
              return {
                userId: item,
                corpId,
              }
            })
            console.log()
          }
        })
    },
    // 刪除當前用戶
    sysRoledelManage() {
      if (this.checkeduserIdList.length < 1) {
        this.$message.warning('至少選擇一條數據')
        return
      }
      sysRoledelManage({ roleId: this.roleId, userIdList: this.checkeduserIdList })
        .then((res) => {
          if (res.success) {
            this.$message.success('刪除成功')
            this.getuserManageList(this.roleId, this.corpId)
            this.visible = false
            this.roleId = ''
          } else {
            this.$message.error('刪除失敗：' + res.message)
          }
        })
        .catch((err) => {
          this.$message.error('刪除錯誤：' + err.message)
        })
    },
    handleCancel() {
      this.visible = false
      this.roleId = ''
    },
  },
}
