//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getOrgTree } from '@/api/modular/system/orgManage'
import { sysRoleOwnData, sysRoleGrantData } from '@/api/modular/system/roleManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'

export default {
  data () {
    return {
      labelCol: {
        style: { 'padding-right': '20px' },
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      orgTreeData: [],
      expandedKeys: [],
      checkedKeys: [],
      visible: false,
      confirmLoading: false,
      formLoading: true,
      autoExpandParent: true,
      selectedKeys: [],
      subValues: [],
      roleEntity: [],
      dataScopeTypeData: [],
      orgTreeShow: false,
      replaceFields: {
        key: 'id'
      },
      form: this.$form.createForm(this)
    }
  },

  methods: {
    // 初始化方法
    roleOrg (record) {
      this.roleEntity = record
      this.visible = true
      this.formLoading = true
      this.sysDictTypeDropDown()
      this.form.getFieldDecorator('dataScopeType', { initialValue: record.dataScopeType.toString() })
      this.handleChange(record.dataScopeType)
    },

    /**
     * 獲取字典數據
     */
    sysDictTypeDropDown () {
      // 數據範圍
      sysDictTypeDropDown({ code: 'data_scope_type' }).then((res) => {
        this.dataScopeTypeData = res.data
        this.formLoading = false
      })
    },

    // 範圍下拉框事件
    handleChange (value) {
      // eslint-disable-next-line eqeqeq
      if (value == '5') {
        this.formLoading = true
        this.orgTreeShow = true
        // 獲取機構樹
        this.getOrgTree()
        // 已關聯數據
        this.sysRoleOwnData(this.roleEntity)
      } else {
        this.orgTreeShow = false
        // 清理已選中機構
        this.checkedKeys = []
      }
    },

    /**
     * 獲取機構樹
     */
    getOrgTree () {
      getOrgTree().then((res) => {
        if (res.success) {
          this.orgTreeData = res.data
          // 默認展開
          this.orgTreeData.forEach(item => {
            this.expandedKeys.push(item.id)
          })
        }
      })
    },

    /**
     * 此角色已有數據列表
     */
    sysRoleOwnData (record) {
      sysRoleOwnData({ id: record.id }).then((res) => {
        if (res.success) {
          this.checkedKeys = res.data
        }
        this.formLoading = false
      })
    },

    onExpand (expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },
    onCheck (checkedKeys) {
      this.checkedKeys = checkedKeys
    },
    onSelect (selectedKeys, info) {
      this.selectedKeys = selectedKeys
    },

    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          const checkedKeys = this.checkedKeys.checked === undefined ? this.checkedKeys : this.checkedKeys.checked
          sysRoleGrantData({ id: this.roleEntity.id, grantOrgIdList: checkedKeys, dataScopeType: values.dataScopeType }).then((res) => {
            this.confirmLoading = false
            if (res.success) {
              this.$message.success('授權成功')
              this.$emit('ok', values)
              this.handleCancel()
            } else {
              this.$message.error('授權失敗：' + res.message)
            }
          }).finally((res) => {
            this.confirmLoading = false
          })
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleCancel () {
      this.form.resetFields()
      // 清空已選擇的
      this.checkedKeys = []
      // 清空已展開的
      this.expandedKeys = []
      this.visible = false
      // 隱藏機構樹
      this.orgTreeShow = false
    }
  }
}
