//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { SysMenuTreeForGrant } from '@/api/modular/system/menuManage'
import { sysRoleOwnMenu, sysRoleGrantMenu } from '@/api/modular/system/roleManage'

export default {
  data() {
    return {
      labelCol: {
        style: { 'padding-right': '20px' },
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      menuTreeData: [],
      expandedKeys: [],
      checkedKeys: [],
      visible: false,
      confirmLoading: false,
      formLoading: true,
      autoExpandParent: true,
      selectedKeys: [],
      subValues: [],
      roleEntity: [],
      replaceFields: {
        key: 'id'
      },
      form: this.$form.createForm(this),
      commitKeys: [],
      leastChilds: []
    }
  },
  methods: {
    // 初始化方法
    roleMenu(record) {
      this.formLoading = true
      this.roleEntity = record
      this.visible = true
      this.getMenuTree()
    },

    /**
     * 獲取菜單列表
     */
    getMenuTree() {
      const _this = this
      SysMenuTreeForGrant().then((res) => {
        if (res.success) {
          _this.menuTreeData = res.data
          _this.getLeastChilds(res.data)
          // 默認展開目錄級
          _this.menuTreeData.forEach(item => {
            _this.expandedKeys.push(item.id)
          })

          _this.expandedMenuKeys(_this.roleEntity)
        }
      })
    },

    getLeastChilds(data) {
      for (let i = 0; i < data.length; i++) {
        this.pushLeastChilds(data[i])
      }
    },

    pushLeastChilds(e) {
      if (e.children.length > 0) {
        this.getLeastChilds(e.children)
        return
      }
      this.leastChilds.push(e.id)
    },

    /**
     * 此角色已有菜單權限
     */
    expandedMenuKeys(record) {
      const _this = this
      sysRoleOwnMenu({ id: record.id }).then((res) => {
        if (res.success) {
          _this.pickCheckedKeys(res.data)
          _this.commitKeys = res.data
        }
        _this.formLoading = false
      })
    },

    pickCheckedKeys(data) {
      for (let i = 0; i < data.length; i++) {
        if (this.leastChilds.includes(data[i])) {
          this.checkedKeys.push(data[i])
        }
      }
    },

    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys
      this.autoExpandParent = false
    },

    onCheck(checkedKeys, info) {
      this.checkedKeys = checkedKeys
      this.commitKeys = checkedKeys.concat(info.halfCheckedKeys)
    },

    onSelect(selectedKeys, info) {
      console.log(selectedKeys)
      console.log(info)
      this.selectedKeys = selectedKeys
    },

    handleSubmit() {
      const _this = this
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          sysRoleGrantMenu({ id: _this.roleEntity.id, grantMenuIdList: _this.commitKeys }).then((res) => {
            if (res.success) {
              _this.$message.success('授權成功')
              _this.confirmLoading = false
              _this.$emit('ok', values)
              _this.handleCancel()
            } else {
              _this.$message.error('授權失敗：' + res.message)
            }
          }).finally((res) => {
            _this.confirmLoading = false
          })
        } else {
          _this.confirmLoading = false
        }
      })
    },
    handleCancel() {
      // 清空已選擇的
      this.checkedKeys = []
      // 清空已展開的
      this.expandedKeys = []
      this.visible = false
    }
  }
}
