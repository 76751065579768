//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { allocateUser, getRolePage, sysRoleDelete } from '@/api/modular/system/roleManage'
import { STable, XCard } from '@/components'
import { handleAppointEleHeight } from '@/utils/common.js'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
    dealResult,
    getUserListMsg,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
import { mapGetters } from 'vuex'
import addForm from './addForm'
import editForm from './editForm'
import roleMenuForm from './roleMenuForm'
import roleOrgForm from './roleOrgForm'
import userTabel from './userTabel'
export default {
    components: {
        OpenData,
        XCard,
        STable,
        addForm,
        editForm,
        userTabel,
        roleMenuForm,
        roleOrgForm,
    },

    data() {
        return {
            // 查詢參數isSystem
            queryParam: {
                // isSystem: 1,
            },
            // 表頭
            columns: [
                {
                    title: '角色名',
                    dataIndex: 'name',
                },
                {
                    title: '角色類型',
                    dataIndex: 'isSystem',
                    scopedSlots: { customRender: 'isSystem' },
                },
                {
                    title: '備註',
                    dataIndex: 'remark',
                },
                {
                    title: '授權用戶',
                    dataIndex: 'manage',
                    scopedSlots: { customRender: 'manage' },
                },
            ],
            // 加載數據方法 必須為 Promise 對象
            loadData: (parameter) => {
                return getRolePage(Object.assign(parameter, this.queryParam)).then((res) => {
                    handleAppointEleHeight('#ygh-content-box', ['.s-table-tool', '.roleSearch'], 66, res)
                    return res.data
                })
            },
            userList: [],
        }
    },
    computed: {
        ...mapGetters(['admintype', 'userInfo']),
    },
    created() {
        init_wx().catch((err) => {
            this.$message.error('微信配置失敗' + err)
        })
        if (
            this.hasPerm('sysRole:edit') ||
            this.hasPerm('sysRole:grantMenu') ||
            this.hasPerm('sysRole:grantData') ||
            this.hasPerm('sysRole:delete')
        ) {
            this.columns.push({
                title: '操作',
                width: '150px',
                dataIndex: 'action',
                scopedSlots: { customRender: 'action' },
            })
        }
    },
    mounted() {
        handleAppointEleHeight('#ygh-content-box', ['.s-table-tool', '.roleSearch'], 66)
    },
    methods: {
        sysRoleDelete(record) {
            sysRoleDelete(record)
                .then((res) => {
                    if (res.success) {
                        this.$message.success('刪除成功')
                        this.$refs.table.refresh()
                    } else {
                        this.$message.error('刪除失敗：' + res.message)
                    }
                })
                .catch((err) => {
                    this.$message.error('刪除錯誤：' + err.message)
                })
        },

        handleOk() {
            this.$refs.table.refresh()
        },
        // 打開企微通訊錄 // mode選擇模式，single表示單選，multi表示多選
        selectPersonAndPart({ id }) {
            const {
                selectedOpenUserIds,
                selectedCorpGroupUserIds,
                selectedDepartmentIds,
                selectedCorpGroupDepartmentIds,
            } = getUserListMsg()
            selectPersonAndDepart({
                selectedOpenUserIds,
                selectedCorpGroupDepartmentIds,
                selectedDepartmentIds,
                selectedCorpGroupUserIds,
            }).then((res) => {
                const { result, resPart } = dealResult(res)
                this.userList = [...result, ...resPart]
                this.allocateUser({ userIdList: [...result, ...resPart], roleId: id })
            })
        },
        // 授權用戶
        allocateUser({ userIdList = [], roleId = 0 }) {
            if (userIdList.length > 0) {
                userIdList = userIdList.map((item) => item.userId)
                allocateUser(Object.assign({ userIdList, roleId })).then((res) => {
                    if (res.success) {
                        this.$message.success('分配成功')
                        this.$refs.table.refresh()
                    } else {
                        this.$message.error('分配失敗：' + res.message)
                    }
                })
            }
        },
        //
        resetData() {
            this.queryParam = {}
            this.$refs.table.refresh()
        },
    },
}
